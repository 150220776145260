/** @jsx jsx */
import get from "lodash/get"
import React from "react"

import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"
import ScrollAnimation from "react-animate-on-scroll"

import { renderLineBreak } from "../helpers"
import { useMobileImageSelector } from "../hooks/use-mobile-image-selector"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "./cta"
import Row from "../components/row"
import Text from "./text"
import RichText from "./rich-text"
import VideoTour from "./video-tour"

const Hero = ({
  background,
  body,
  ctaList,
  customStyle,
  bodyStyle,
  horizontalAlign,
  tagline,
  title,
  type,
  verticalAlign,
  textWidth,
  richText,
  popupVideo,
  animateOnScroll,
  animateManual,
  animateShow,
  isMobile,
  videoTitle,
  videoLink,
  titleStyle={}
}) => {
  const getVerticalAlignment = () => {
    const verticalAlignList = {
      bottom: "flex-end",
      center: "center",
    }

    return verticalAlignList[verticalAlign] || null
  }
  const mobileImageSelector = useMobileImageSelector()
  const [videoHidden, setVideoHidden] = React.useState(false)
  let [backgroundImage, setBackgroundImage] = React.useState("")
  React.useLayoutEffect(() => {
    let image = mobileImageSelector(
      get(background, "source.desktopImage.file.url"),
      get(background, "source.mobileImage.file.url")
    )
    setBackgroundImage(image)
  }, [background])
  const renderMedia = () => {
    let mediaType = null

    const hideVideo = () => {
      setVideoHidden(true)
    }
    if (background) {
      const mediaTypeList = {
        image: () => {
          const imageStyle = {
            display: "block",
            height: type && type === "secondary" ? [220, 220, "100%"] : "100%",
            left: 0,
            m: 0,
            objectFit: "cover",
            position:
              type === "secondary"
                ? ["relative", "relative", "absolute"]
                : "absolute",
            top: 0,
            width: "100%",
          }

          return <img alt="" sx={imageStyle} src={backgroundImage} />
        },
        video: () => (
          <div
            sx={{
              height: "100%",
              left: 0,
              overflow: "hidden",
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <video
              autoPlay
              muted
              loop
              playsInline
              sx={{
                minHeight: "100%",
                minWidth: "100%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              poster={background.source.desktopImage.file.url}
              onError={hideVideo}
            >
              <source src={background.source.video.file.url} />
            </video>
          </div>
        ),
      }

      mediaType = background.type
        ? mediaTypeList[background.type.split("/")[0]]()
        : mediaTypeList.image()
      if (videoHidden) mediaType = mediaTypeList.image()
      mediaType = mobileImageSelector(mediaType, mediaTypeList.image())
    }

    return mediaType
  }
  const heroStyle = {
    flexDirection: "column",
    justifyContent:
      type && type === "secondary"
        ? [null, null, getVerticalAlignment()]
        : getVerticalAlignment(),
    minHeight: type && type === "secondary" ? [null, null, 568] : 568,
    position: "relative",
    ...(type &&
      type === "primary" && {
        height: theme => `calc(100vh - ${theme.sizes.header.height}px)`,
      }),
  }

  const heroTextPosition =
  isMobile ?{ position: "absolute" }:type === "primary"
      ? {
          position: ["absolute", "absolute", "relative"],
          top: 0,
          maxWidth: ["75%", "75%", "none"],
        }
      : { position: "relative" }

      const checkIfWindow = () => {
        return typeof document !== "undefined" && document.getElementsByTagName('h2').length;
      }
       React.useEffect(() => {
        if (checkIfWindow()) {
          let headings = document.getElementsByTagName('h2');
          let count = 0;
          const changeHeadingText = () => {
            document.getElementById("mainHeading").innerHTML = "A " + myArray[count] + "<br>way to build.";
            count = (count === myArray.length - 1) ? 0 : count + 1;
            setTimeout(changeHeadingText, 2000)
          }
          if ( headings[0].textContent === "A smarter, faster, higher-quality, healthierway to build.") {
            headings[0].setAttribute("id", "mainHeading");
            var testData = !!document.getElementById("mainHeading");
            if(testData){
              var myArray = headings[0].textContent.split("way to build.")[0].split("A")[1].split(",");
              setTimeout(changeHeadingText, 20);
            }
          }else{
            headings[0].setAttribute("id", "");
          }
        }
      }, [])
      
  // if (typeof document !== 'undefined') {
  //   let headings = document.getElementsByTagName('h2');
  //   let count = 0;
  //   const changeHeadingText = () => {
  //     document.getElementById("mainHeading").innerHTML = "A " + myArray[count] + "<br>way to build.";
  //     count = (count === myArray.length - 1) ? 0 : count + 1;
  //     setTimeout(changeHeadingText, 2000)
      
  //   }
  //   if (headings.length > 0 && headings[0].textContent === "A smarter, faster, higher-quality, healthierway to build.") {
  //     headings[0].setAttribute("id", "mainHeading");
  //     var testData = !!document.getElementById("mainHeading");
  //     if(testData){
  //       var myArray = headings[0].textContent.split("way to build.")[0].split("A")[1].split(",");
  //       setTimeout(changeHeadingText, 20);
  //     }
      
  //   }
  // }
        
  const renderHeroBody = () => (
    <>
      {tagline && (
        <Text
          customStyle={{
            color: type === "secondary" ? ["text", "text", "white"] : "white",
            fontWeight: "bold",
            mb: theme => theme.spacing.vertical.md,
          }}
          variant="h4"
        >
          {tagline}
        </Text>
      )}
    
      {title && (
        <Text
          customStyle={{
            color: type === "secondary" ? ["text", "text", "white"] : "white",
            fontSize: [6, 6, 7],
            maxWidth: textWidth || "none",
            opacity: 1,
            ...titleStyle
          }}
          type="h2"
          variant="h1"
        >
          {renderLineBreak(title)}
        </Text>
      )}
      {body && (
        <Text
          customStyle={{
            color: type === "secondary" ? ["text", "text", "white"] : "white",
            fontSize: 16,
            fontWeight: 400,
            maxWidth: textWidth || ["none", "none", "40%"],
            mt: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
            textShadow:
              type && type === "secondary"
                ? [null, null, "1px 1px 7px rgba(0,0,0,0.6)"]
                : "1px 1px 7px rgba(0,0,0,0.6)",
            br: {
              display: ["none", "none", "initial"],
            },
            ...bodyStyle,
          }}
        >
          {renderLineBreak(body)}
        </Text>
      )}
      {richText && (
        <RichText
          data={richText}
          listItemTextStyle={{
            m: 0,
            color: type === "secondary" ? ["text", "text", "white"] : "white",
            fontSize: 16,
            fontWeight: 400,
            textShadow:
              type && type === "secondary"
                ? [null, null, "1px 1px 7px rgba(0,0,0,0.6)"]
                : "1px 1px 7px rgba(0,0,0,0.6)",
            ...bodyStyle,
          }}
          listItemStyle={{
            "::before": {
              content: '"\\2022"',
              fontSize: 1,
              position: "absolute",
              top: "5px",
              left: "-12px",
              color: type === "secondary" ? ["text", "text", "white"] : "white",
            },
          }}
          listStyle={{ ml: 10, p: { mt: 0 } }}
          textStyle={{
            color: type === "secondary" ? ["text", "text", "white"] : "white",
            fontSize: 16,
            fontWeight: "400",
            maxWidth: textWidth || ["none", "none", "40%"],
            mt: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
            textShadow:
              type && type === "secondary"
                ? [null, null, "1px 1px 7px rgba(0,0,0,0.6)"]
                : "1px 1px 7px rgba(0,0,0,0.6)",
            br: {
              display: ["none", "none", "initial"],
            },
            ...bodyStyle,
          }}
        />
      )}
      {ctaList && (
        <ul
          sx={{
            display: "flex",
            justifyContent: horizontalAlign || null,
            listStyle: "none",
            m: 0,
            mt: 3,
            p: 0,
          }}
        >
          {ctaList.map(ctaData => (
            <li
              key={kebabCase(ctaData.text)}
              sx={{ m: 0, mr: 3, ":last-child": { mr: 0 } }}
            >
              <CTA link={ctaData.link || "/"} type={ctaData.type}>
                {ctaData.text}
              </CTA>
            </li>
          ))}
        </ul>
      )}

      { videoTitle && videoLink &&
        <div
          sx={{
            pointerEvents: "auto",
          }}
        >
          <VideoTour
            video={{ externalUrl: { externalUrl: videoLink } }}
            type="mini"
            videoTitle={videoTitle}
          />
        </div>
              }
    </>
  )
  const animationStyle = {
    "&.fadeIn": { animation: "fadeIn 1s", opacity: 1 },
    "&.fadeOut": {
      animation: "fadeOut 1s",
      opacity: 0,
    },
    "@keyframes fadeIn": {
      from: { transform: "translateY(20px)", opacity: 0 },
      to: { transform: "translateY(0px)", opacity: 1 },
    },
    "@keyframes fadeOut": {
      from: { transform: "translateY(0px)", opacity: 1 },
      to: { transform: "translateY(20px)", opacity: 0 },
    },
  }


  return (
    <Flex sx={{ ...heroStyle, ...customStyle }}>
      {backgroundImage && renderMedia()}
      {(body || ctaList || tagline || title) && (
        <Container >
          <Row>
            <Column
              customStyle={{
                py:
                  type && type === "secondary"
                    ? theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        6,
                      ]
                    : 6,
                ...heroTextPosition,
                textAlign: horizontalAlign || null,
              }}
            >
              {animateOnScroll ? (
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOut="fadeOut"
                  sx={animationStyle}
                >
                  {renderHeroBody()}
                </ScrollAnimation>
              ) : (
                <div
                  className={
                    animateManual && (animateShow ? "fadeIn" : "fadeOut")
                  }
                  sx={animationStyle}
                >
                  {renderHeroBody()}
                </div>
              )}
            </Column>
          </Row>
          {popupVideo && (
            <div
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: type === "secondary" ? [220, 220, "100%"] : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                pointerEvents: "none",
              }}
            >
              <div
                sx={{
                  pointerEvents: "auto",
                }}
              >
                <VideoTour
                  video={{ externalUrl: { externalUrl: popupVideo } }}
                  type="mini"
                />
              </div>
            </div>
          )}
        </Container>
      )}
    </Flex>
  )
}

Hero.propTypes = {
  background: PropTypes.shape({
    type: PropTypes.string,
    source: PropTypes.oneOfType([
      PropTypes.shape({
        mobileImage: PropTypes.shape({
          file: PropTypes.shape({ url: PropTypes.string }),
          title: PropTypes.string,
        }),
        desktopImage: PropTypes.shape({
          file: PropTypes.shape({ url: PropTypes.string.isRequired }),
          title: PropTypes.string,
        }),
      }),
      PropTypes.string,
    ]).isRequired,
  }),
  body: PropTypes.string,
  ctaList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  customStyle: PropTypes.object,
  horizontalAlign: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  textWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  verticalAlign: PropTypes.string,
}

export default Hero
