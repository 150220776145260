export const paramsCheck = () => {
  let url = typeof window !== "undefined" ? window.location.href : ""
  if (url) {
    url = decodeURIComponent(url) // decoding url
    let arr = url.split("?")
    if (arr.length > 1 && arr[arr.length - 1].includes("utm")) {
      let objarr = arr[arr.length - 1].split("&")
      let urlobj = {}
      let GoogleAdData = []
      objarr.forEach(ele => {
        if (ele) {
          var [key, value] = ele.split("=")
          if (value) {
            urlobj[key] = value
            GoogleAdData.push(value)
          }
        }
      })
      let AdData = {
        "Google Ad Data": GoogleAdData.join("~"),
      }
      urlobj = { ...urlobj, ...AdData }

      if (Object.keys(urlobj).length > 1) {
        localStorage.setItem("parameter", true)
        localStorage.setItem("formObj", JSON.stringify(urlobj))
      } else {
        localStorage.setItem("parameter", false)
      }
    } else {
      let parameter = localStorage.getItem("parameter")
      if (parameter) {
        if (localStorage.getItem("formObj")) {
          localStorage.setItem("parameter", true)
        } else {
          localStorage.setItem("parameter", false)
        }
      } else {
        localStorage.setItem("parameter", false)
      }
    }
  }
}
// Example url
//https://www.plantprefab.com/?
// &utm_source=Google&utm_medium=SEM&
// utm_campaign=10577311628&utm_content=c-449720600304&utm_term=plant%20prefab-e&
// gclid=EAIaIQobChMIrcuJj4PV6gIVlxatBh1GVgVkEAAYASAAEgJA4PD_BwE

// &utm_source=Google&utm_medium=SEM&utm_campaign=10577311628&utm_content=c-449720600304&utm_term=plant%20prefab-e&gclid=EAIaIQobChMIrcuJj4PV6gIVlxatBh1GVgVkEAAYASAAEgJA4PD_BwE
