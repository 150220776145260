/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Column from "../components/column"
import Row from "../components/row"
import CTA from "../components/cta"
import { isVariableValue } from "../helpers"
import Carousel from "./carousel"
import PageLink from "./page-link"
import Text from "./text"
import queryString from "query-string"

const ContentCard = ({
  baths,
  beds,
  imageList,
  squareFootage,
  title,
  titleLink,
  configuratorUrl,
  autoScroll,
  filter,
}) => {
  const imageStyle = {
    display: "block",
    height: [235, 235, 400],
    m: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
  }
  const renderImage = (imageData, index) => {
    const imageID = `${imageData.id}-${index}`

    return imageData.fluid ? (
      <Img
        sx={{ height: imageStyle.height }}
        fluid={imageData.fluid}
        key={imageID}
      />
    ) : (
      <img
        sx={imageStyle}
        src={imageData.url || imageData.file?imageData.file.url:""}
        alt={imageData.title}
        key={imageID}
      />
    )
  }

  let newURL
  let url = typeof window !== "undefined" ? window.location.href : ""
  if (url) {
    if (localStorage.getItem("parameter")) {
      let urlformdata = JSON.parse(localStorage.getItem("formObj"))
      if (configuratorUrl) {
        newURL = queryString.stringifyUrl(
          { url: configuratorUrl, query: urlformdata },
          { encode: true, sort: false }
        )
      }
    }
  }

  const renderCarousel = () => {
    return (
      <Carousel type="secondary" autoScroll={autoScroll} filter={filter}>
        {imageList.map((imageData, index) => (
          <div className="image" sx={{ position: "relative" }}>
            <div
              className="overlay"
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 99,
              }}
            ></div>
            {renderImage(imageData, index)}
          </div>
        ))}
      </Carousel>
    )
  }
  const renderTitle = () => {
    return titleLink && titleLink.to ? (
      <PageLink
        link={titleLink.to}
        // type={titleLink.type}
        customStyle={{ pr: 0, display: "block" }}
        target="_self"
      >
        <Text type="h3">{title}</Text>
      </PageLink>
    ) : (
      <Text type="h3">{title}</Text>
    )
  }

  const content = (
    <div
      sx={{
        backgroundColor: "white",
        border: "1px solid #cccccc",
        borderRadius: 5,
        height: "100%",
        ...(titleLink && {
          ":hover": {
            ".image .overlay": {
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 100%)",
            },
          },
        }),
      }}
    >
      {titleLink && titleLink.to ? (
        <PageLink
          link={titleLink.to}
          // type={titleLink.type}
          customStyle={{ pr: 0, display: "block" }}
          target="_self"
        >
          {imageList.length > 1 && renderCarousel()}
          {imageList.length === 1 && (
            <div className="image" sx={{ position: "relative" }}>
              <div
                className="overlay"
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 99,
                }}
              ></div>
              {renderImage(imageList[0])}
            </div>
          )}
        </PageLink>
      ) : (
        <div>
          {imageList.length > 1 && renderCarousel()}
          {imageList.length === 1 && (
            <div className="image" sx={{ position: "relative" }}>
              <div
                className="overlay"
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 99,
                }}
              ></div>
              {renderImage(imageList[0])}
            </div>
          )}
        </div>
      )}
      <div sx={{ py: 3, px: 4 }}>
        <Row>
          <Column>{renderTitle()}</Column>
          {/* {configuratorUrl ? (
            <Column
              size={[12, 12, 5]}
              customStyle={{
                display: ["none", "none", "none", "block"],
              }}
            >
              <CTA
                link={newURL ? newURL : configuratorUrl}
                type={"secondary"}
                linkType="external"
                customStyle={{
                  paddingRight: "35px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                }}
              >
                {"Design in 3D"}
              </CTA>
            </Column>
          ) : null} */}
        </Row>
        {titleLink && titleLink.to ? (
          <PageLink link={titleLink.to} 
          // type={titleLink.type}  
          target="_self">
            <Text customStyle={{ fontWeight: "heading", marginTop: "8px" }}>
              {`${isVariableValue(parseInt(beds)) ? "Variable" : beds} bed, ${
                isVariableValue(parseInt(baths)) ? "Variable" : baths
              } bath, ${
                isVariableValue(parseInt(squareFootage))
                  ? "Variable"
                  : squareFootage
              } sq ft`}
            </Text>
          </PageLink>
        ) : (
          <Text customStyle={{ fontWeight: "heading", marginTop: "8px" }}>
            {`${isVariableValue(parseInt(beds)) ? "Variable" : beds} bed, ${
              isVariableValue(parseInt(baths)) ? "Variable" : baths
            } bath, ${
              isVariableValue(parseInt(squareFootage))
                ? "Variable"
                : squareFootage
            } sq ft`}
          </Text>
        )}
      </div>
    </div>
  )
  return content
}

ContentCard.propTypes = {
  title: PropTypes.string.isRequired,
  configuratorUrl: PropTypes.string,
  baths: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  beds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  squareFootage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ url: PropTypes.string.isRequired }),
      PropTypes.shape({ fluid: PropTypes.object.isRequired }),
    ]).isRequired
  ),
}

export default ContentCard
