import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "../../src/components/seo"

const Page = ({ children, data }) => (
  <>
    <SEO title={data.title} description={data.description.description} />
    {children}
  </>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    description: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    sectionList: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.shape({
          id: PropTypes.string.isRequired,
          internal: PropTypes.shape({
            content: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Page

export const query = graphql`
  fragment PageData on ContentfulPage {
    description {
      description
    }
    id
    sectionList {
      content {
        id
        internal {
          content
        }
      }
      contentCards {
        ... on ContentfulBasicCard {
          title
          slug
        }
      }
      id
      slug
    }
    slug
    title
  }

  fragment BladePatternData on ContentfulPage {
    bladePatternList {
      title
      slug
      anchorLink
      ctaTitle
      ctaDestination
      description {
        description
      }
      desktopImage {
        title
        file {
          url
          fileName
        }
      }
      mobileImage {
        title
        file {
          url
          fileName
        }
      }
      contentCards {
        ... on ContentfulBasicCard {
          ctaDestination {
            ctaDestination
          }
          ctaDestinationExternal {
            ctaDestinationExternal
          }
          ctaTitle
          description {
            json
          }
          bios {
            json
          }
          desktopImage {
            title
            file {
              url
              fileName
            }
          }
          mobileImage {
            title
            file {
              url
              fileName
            }
          }
          video {
            file {
              url
            }
          }
          title
          slug
        }
      }
    }
  }
`

// contentCards {
//   ... on ContentfulBasicCard {
//     title
//     slug
//   }
//   topics {
//     name
//     quote {
//       json
//     }
//   }
// }
// id
// slug
// }
// slug
// title
// }
