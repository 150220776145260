/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Column from "../components/column"
import Row from "../components/row"
import PageLink from "./page-link"
import Text from "./text"

const BlogContentCard = ({ title, thumbnail, teaser, link, linkType }) => {
  const imageStyle = {
    display: "block",
    height: [235, 235, 400],
    m: 0,
    borderRadius: "10px 10px 0px 0px ",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
  }

  const renderImage = () => {
    return (
      <img style={imageStyle} src={thumbnail.file.url} alt={thumbnail.title} />
    )
  }

  const renderTitle = () => {
    return (
      <Text
        type="h3"
        customStyle={{
          fontSize: 22,
          pb: 2,
          pr: 0,
          mx: theme => [theme.spacing.vertical.sm],
          mb: 2,
          // color: "inherit",
          // fontWeight: "500",
          // ...titleStyle,
        }}
      >
        {title}
      </Text>
    )
  }

  const renderTeaser = () => {
    return (
      teaser && (
        <Text
          customStyle={{
            color: "#aaa",
            mx: theme => [theme.spacing.vertical.sm],
            fontSize:17
          }}
        >
          {teaser.teaser}
        </Text>
      )
    )
  }
  return (
    <div
      sx={{
        backgroundColor: "white",
        border: "2px solid #cccccc",
        borderRadius: 10,
        // ...(title && {
        // ":hover": {
        //     ".image .overlay": {
        //     background:
        //         "linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 100%)",
        //     },
        // },
        // }),
      }}
    >
      <PageLink
        link={link || "/"}
        customStyle={{
          textDecoration: "none",
          // mb: 3,
          pr: 0,
          display: "block",
          color: "text",
          ":hover": { color: "primary" },
          // ...customStyle,
        }}
        type={linkType}
      >
        {/* <div className="image" style={{ position: "relative" }}>
            <div
              className="overlay"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 99,
              }}
            ></div> */}
        {thumbnail && renderImage()}
        {/* </div> */}
        <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
          {title && renderTitle()}
          {teaser && renderTeaser()}
        </div>
      </PageLink>
    </div>
  )
}

export default BlogContentCard
