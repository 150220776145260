/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "./container"
import Text from "./text"
import Row from "./row"
import Column from "./column"
import theme from "../gatsby-plugin-theme-ui/index"
import { Button } from "@theme-ui/components"
import { findBySlug } from "../helpers"
import PageLink from "./page-link"
import CTA from "./cta"
import RichText from "./rich-text"
import NewsletterForm from "./newsletter-form"
import ContentCard from "./content-card"
import BlogContentCard from "./blog-content-card"

import playButton from "../images/play-button.svg"
import playButtonBlue from "../images/play-button-blue.svg"

const BlogCenter = ({ news, category, talks, data }) => {
  const [showAll, setShowAll] = React.useState(false)
  const [showAllTalks, setShowAllTalks] = React.useState(false)
  const [showContact, setShowContact] = React.useState(false)

  const renderMainPanel = () => {
    const columnStyle = {
      py: theme => [
        // theme.spacing.vertical.sm,
        // theme.spacing.vertical.sm,
        2,
      ],

      // ":nth-child(3n+2)":{
      //   order:1,
      //   display: "inline-block"
      // },
      // ":nthChild(3n+1)":{
      //   order:1,
      // }
    }
    return (
      <Row customStyle={{ px: 0 }}>
        <Column>
          <Text type="h3" customStyle={{ pb: 20, fontSize: 28 }}>
            Latest
          </Text>
          {/* <Row customStyle={{display:"flex"}}>          */}
          <div
            id="rows"
            className="mase-grid"
            sx={{
              columnCount: [1, 2],
              // columnGap:"2%",
            }}
          >
            {news
              .sort((a, b) => {
                const dateA = new Date(a.date)
                const dateB = new Date(b.date)
                return dateB - dateA
              })
              .filter(ele => ele.slug !== "dummy")
              .slice(...(showAll ? [0] : [0, 6]))
              .map(article => (
                <>
                  {/* {article.slug!="dummy"? */}
                  <Column
                    // size={[12, 6]}
                    customStyle={columnStyle}
                  >
                    <BlogContentCard
                      thumbnail={article.image}
                      title={article.title}
                      link={`blog/${article.slug || ""}`}
                      linkType="internal"
                      teaser={article.teaser}
                    />
                  </Column>
                  {/* :null}      */}
                </>
              ))}
            {/* </Row> */}
          </div>
          {news.length > 5 && (
            <Text
              customStyle={{
                color: "primary",
                cursor: "pointer",
                mt: [4],
                userSelect: "none",
              }}
            >
              <a
                href="/#"
                onClick={e => {
                  e.preventDefault()
                  setShowAll(!showAll)
                }}
              >
                {showAll ? "Less" : "More"}
                <span
                  sx={{
                    fontSize: 14,
                    ml: 2,
                    display: "inline-block",
                    transform: showAll
                      ? "scaleX(1.8) translateY(7px) rotate(180deg)"
                      : "scaleX(1.8) translateY(-5px)",
                  }}
                >
                  {"\u2304"}
                </span>
              </a>
            </Text>
          )}
        </Column>
      </Row>
    )
  }
  const renderLeftSidebar = () => {
    const Item = ({ text, link, linkType, showIcon }) => (
      <PageLink
        link={link}
        type={linkType}
        customStyle={{
          cursor: "pointer",
          display: "block",
          pb: 2,
          px: 0,
          mb: 0,
          mr: 0,
          userSelect: "none",
          overflowX: "auto",
          ":last-child": {
            pr: 0,
          },
          "-webkit-tap-highlight-color": "transparent",
          background: "white",
          color: ["primary", "primary", "primary", "text"],
          ":hover": {
            color: "primary",
          },
          fontWeight: "300",
          fontSize: 3,
          fontFamily: "body",
          ...(showIcon && {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }),
        }}
      >
        {showIcon && (
          <>
            <img
              sx={{
                width: 18,
                mr: 2,
                mt: "6px",
                display: ["none", "none", "none", "block"],
              }}
              src={playButton}
            />
            <img
              sx={{
                width: 18,
                mr: 2,
                mt: ["3px", "5px", "5px"],
                display: ["block", "block", "block", "none"],
              }}
              src={playButtonBlue}
            />
          </>
        )}
        <div>{text}</div>
      </PageLink>
    )
    return (
      <div>
        <div sx={{ mb: [4, 4, 4, 4] }}>
          <Text
            customStyle={{
              color: ["text", "text", "text", "#888"],
              fontSize: [28, 28, 28, 12],
              fontWeight: ["500"],
              pb: 2,
              display: "block",
            }}
          >
            <span sx={{ display: ["none", "none", "none", "inline"] }}>
              VIDEOS
            </span>
            <span sx={{ display: ["inline", "inline", "inline", "none"] }}>
              Videos
            </span>
          </Text>
          {talks
            .sort((a, b) => {
              const dateA = new Date(a.date)
              const dateB = new Date(b.date)
              return dateB - dateA
            })
            // .slice(...(showAllTalks ? [0] : [0, 3]))
            .map(talk => (
              <Item
                showIcon={true}
                text={talk.title}
                link={talk.link}
                linkType="external"
              />
            ))}
          {talks.length > 6 && (
            <Text
              customStyle={{
                color: "primary",
                cursor: "pointer",
                mt: [4, 0],
                userSelect: "none",
              }}
            >
              <a
                href="/#"
                onClick={e => {
                  e.preventDefault()
                  setShowAllTalks(!showAllTalks)
                }}
              >
                {showAllTalks ? "Less" : "More"}
                <span
                  sx={{
                    fontSize: 14,
                    ml: 2,
                    display: "inline-block",
                    transform: showAllTalks
                      ? "scaleX(1.8) translateY(7px) rotate(180deg)"
                      : "scaleX(1.8) translateY(-5px)",
                  }}
                >
                  {"\u2304"}
                </span>
              </a>
            </Text>
          )}
        </div>
      </div>
    )
  }
  const renderMessage = () => {
    return (
      <Row>
        <Column>
          <Text type="h3" customStyle={{ pb: 20, fontSize: 28 }}>
            Awesome blog posts coming soon!
          </Text>
          <Text customStyle={{ pb: 20 }}>
            (We just launched our blog. Please check out "Latest Posts" to read
            the articles we've published so far.)
          </Text>
          <Text customStyle={{ pb: 20 }}>Thanks for your interest!</Text>
        </Column>
      </Row>
    )
  }

  const renderRightSidebar = () => {
    const bladePatternData = findBySlug({
      list: data.bladePatternList,
      slug: "blog-center-right-sidebar-module",
    })
    return (
      <Row customStyle={{ pl: [0, 0, 0, 6] }}>
        <Column>
          {bladePatternData.contentCards.map(card => (
            <div sx={{ pb: [0, 0, 0, 5], pt: [5, 5, 5, 0] }}>
              <Text type="h3" customStyle={{ pb: 20, fontSize: 28 }}>
                {card.title}
              </Text>
              <RichText
                data={card.description}
                textStyle={{ fontFamily: "body", fontSize:17 }}
              ></RichText>
              {card.slug !== "blog-newsletter" ? (
                <CTA
                  link={
                    (card.ctaDestination &&
                      card.ctaDestination.ctaDestination) ||
                    (card.ctaDestinationExternal &&
                      card.ctaDestinationExternal.ctaDestinationExternal) ||
                    "/"
                  }
                  linkType={
                    card.ctaDestinationExternal ? "external" : "internal"
                  }
                  customStyle={{ fontSize: 2, py: 2, pl: 3, mt: [3, 1, 0] }}
                >
                  {card.ctaTitle}
                </CTA>
              ) : (
                <Button
                  sx={{
                    ...theme.forms.buttonChevron,
                    fontSize: 14,
                    pr: 7,
                    py: "5px",
                    pl: 19,
                    mt: 26,
                    display: "flex",
                    justifyContent: "space-between",
                    width: 190,
                    color: "primary",
                    border: theme => `2px solid ${theme.colors.primary}`,
                    background: "transparent",
                    whiteSpace: "nowrap",
                    ":hover": {
                      border: theme => `2px solid ${theme.colors.secondary}`,
                      color: "secondary",
                    },
                  }}
                  onClick={() => setShowContact(true)}
                >
                  {card.ctaTitle}
                </Button>
              )}
            </div>
          ))}
        </Column>
      </Row>
    )
  }
  return (
    <Container
      customStyle={
        {
          // mx:theme=> [theme.spacing.vertical.sm, 0, theme.spacing.horizontal]
        }
      }
    >
      {showContact && <NewsletterForm onClose={() => setShowContact(false)} />}
      <div sx={{ display: ["block", "block", "block", "flex"] }}>
        <div
          sx={{
            width: "265px",
            display: ["none", "none", "none", "block"],
          }}
        >
          {renderLeftSidebar()}
        </div>
        <Row customStyle={{ width: "100%", pl: [0, 0, 0, "75px"] }}>
          {/* {news && news.length>0? */}
          {news && news.filter(ele => ele.slug !== "dummy").length > 0 ? (
            <Column size={[12, 12, 12, 7]}>{renderMainPanel()}</Column>
          ) : (
            <Column size={[12, 12, 12, 7]}>{renderMessage()}</Column>
          )}
          <Column size={[12, 12, 12, 5]} customStyle={{ mt: 0 }}>
            {renderRightSidebar()}
          </Column>
          <Column
            size={[12]}
            customStyle={{
              display: ["block", "block", "block", "none"],
              mt: 6,
            }}
          >
            {renderLeftSidebar()}
          </Column>
        </Row>
      </div>
    </Container>
  )
}
export default BlogCenter
